import React, { Component, Suspense } from "react";
//import Header from "../"+window.sessionStorage.getItem("homeName")+"header"//"../layout/header";
import $ from "jquery";
import * as ReactDOM from "react-dom";
import TrustedBy from "../home/trustedBy";
import { Link } from "react-router-dom";
import ContactForm from "../home/contactus-form";
// import Contactus from "./contactus"
// import Telehealth from "./telehealth"
// import Aboutus from "./aboutus"
import Favicon from "react-favicon";
import { Helmet } from "react-helmet";
//import homeImg4 from "../image/theme/home4.png";
//import homeImg1 from "../image/theme/home1.png";
//import homeImg3 from "../image/theme/home3.png";
//import homeImg2 from "../image/theme/home4.png";
//import whyicon1 from "../image/theme/why-icon1.png";
//import whyicon2 from "../image/theme/why-icon2.png";
//import whyicon3 from "../image/theme/why-icon3.png";
import loaderImg from "../image/theme/loader.gif";
// import ChatBoat from "../dashboard/chatBoat";
//import "../css/style.css";
import { apiCalling } from "../apiService";

let folderName = window.sessionStorage.getItem("homeName");
let Home = React.lazy(() => import("../" + folderName + "/home"));
let Header = React.lazy(() => import("../" + folderName + "/header"));
let Footer = React.lazy(() => import("../" + folderName + "/footer"));

export default class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteId: window.sessionStorage.getItem("siteId"),
      siteUrl: window.location.host,
      isLoader: false,
    };
  }
  componentDidMount() {
    // console.log("page properties", this.props);
    !this.props.match.params.token && this.getLoadHomePage();
    this.props.match.params.token && this.decryptPulseURL();
    //if(window.sessionStorage.getItem("pathname")!="null"){
    // window.location.href = window.sessionStorage.getItem("pathname")
    // }
    // window.sessionStorage.setItem("pathname",window.location.pathname)
    //alert(window.sessionStorage.getItem("pathname",window.location.pathname))

    if (folderName) {
      this.setState({ isLoader: true });
    }
  }

  decryptPulseURL = () => {
    // console.log("inside redirection");
    // console.log(this.props);
    const decryptPulseJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "decryptPulseUrl",
        encryptedData: this.props.match.params.token,
      }),
    };
    apiCalling(decryptPulseJson).then((data) => {
      if (data.success == "1") {
        // console.log("data", data);
        window.sessionStorage.setItem("userId", data.userData[0].userId);
        window.sessionStorage.setItem("emailId", data.userData[0].emailId);
        window.sessionStorage.setItem("userName", data.userData[0].userName);
        window.sessionStorage.setItem(
          "logedSiteId",
          data.userData[0].siteUrl.split("/").pop()
        );
        window.sessionStorage.setItem("isLogin", "true");
        $("#showMyName").text(data.userData[0].userName);
        window.sessionStorage.setItem(
          "mobileNumber",
          data.userData[0].mobileNumber
        );
        window.sessionStorage.setItem("role", data.userData[0].role);
        $(".doctorMenuTop").show();

        this.setState(
          {
            siteId: data.userData[0].siteUrl.split("/").pop(),
          },
          () => {
            // console.log("this.state.siteId", this.state.siteId);
            // window.location.host.includes("localhost") &&
            //   this.callSiteRequest();
            // this.props.match.params.siteId &&
            this.callSiteRequest();
          }
        );
      }
    });
  };
  callSiteRequest() {
    // console.log(this.props);
    let siteurl = ""; //this.state.siteUrl//""
    window.sessionStorage.setItem("pathname", window.location.pathname);

    if (!this.state.siteId) {
      siteurl = this.state.siteUrl;
    } else {
      window.sessionStorage.setItem("siteId", this.state.siteId);
    }
    const siteCallRequest = {
      method: "POST",
      body: JSON.stringify({
        functionName: "getSiteDetails",
        siteId: this.state.siteId,
        siteUrl: siteurl,
      }),
    };
    apiCalling(siteCallRequest).then((data) => {
      // alert("2")
      if (data.success === "1") {
        //alert("3")
        var location = data.siteDetailsData[0].siteData[0].siteName;
        let is_theme_based = data.siteDetailsData[0].siteData[0].is_theme_based;
        let project_folder = data.siteDetailsData[0].siteData[0].project_folder;
        var folder = "DoctorsCabin";
        if (is_theme_based === false && project_folder !== "") {
          folder = project_folder;
        } else {
          folder = data.siteDetailsData[0].siteData[0].themeName;
        }
        if (data.siteDetailsData[0].siteData[0].id) {
        } else {
          folder = "DoctorsCabin";
        }
        //folder = 'SAANVI'
        window.sessionStorage.setItem(
          "siteId",
          data.siteDetailsData[0].siteData[0].id
        );
        window.sessionStorage.setItem(
          "themeId",
          data.siteDetailsData[0].siteData[0].themeId
        );

        window.sessionStorage.setItem("themeVersion", "0");
        window.sessionStorage.setItem("homeName", folder);
        window.sessionStorage.setItem("reload", "1");
        window.sessionStorage.setItem(
          "dashboard_html",
          data.siteDetailsData[0].siteData[0].dashboard_html
        );
        window.sessionStorage.setItem(
          "theme_custom_color",
          data.siteDetailsData[0].siteData[0].theme_custom_color
        );
        window.sessionStorage.setItem(
          "contact_emailId",
          data.siteDetailsData[0].siteData[0].emailId
        );
        window.sessionStorage.setItem(
          "contact_no",
          data.siteDetailsData[0].siteData[0].contact_no
        );
        window.sessionStorage.setItem(
          "fav_icon",
          data.siteDetailsData[0].siteData[0].fav_icon
        );
        window.sessionStorage.setItem(
          "seo_description",
          data.siteDetailsData[0].siteData[0].seo_description
        );
        window.sessionStorage.setItem(
          "seo_keywords",
          data.siteDetailsData[0].siteData[0].seo_keywords
        );
        window.sessionStorage.setItem(
          "seo_title",
          data.siteDetailsData[0].siteData[0].seo_title
        );
        window.sessionStorage.setItem(
          "slug",
          data.siteDetailsData[0].siteData[0].slug
        );
        window.sessionStorage.setItem(
          "isIDALogin",
          data.siteDetailsData[0].siteData[0].isIDALogin
        );
        //window.sessionStorage.setItem("google_analytics_script",data.siteDetailsData[0].siteData[0].google_analytics_script);
        var google_analytics_script =
          data.siteDetailsData[0].siteData[0].google_analytics_script;

        var clarityId = "";
        var googleId = "";
        if (google_analytics_script.length < 30) {
          var Gdata = google_analytics_script.split(",");
          if (Gdata[0]) {
            googleId = Gdata[0];
          }
          if (Gdata[1]) {
            clarityId = Gdata[1];
          }
        }

        window.sessionStorage.setItem("clarityId", clarityId);
        window.sessionStorage.setItem("googleId", googleId);
        window.sessionStorage.setItem(
          "siteName",
          data.siteDetailsData[0].siteData[0].siteName
        );
        window.sessionStorage.setItem(
          "book_appointment_terms",
          data.siteDetailsData[0].siteData[0].book_appointment_terms
        );
        window.sessionStorage.setItem(
          "video_chat_terms",
          data.siteDetailsData[0].siteData[0].video_chat_terms
        );
        window.sessionStorage.setItem(
          "hospital_working_time",
          data.siteDetailsData[0].siteData[0].hospital_working_time
        );
        window.sessionStorage.setItem(
          "site_url",
          data.siteDetailsData[0].siteData[0].site_url
        );
        window.sessionStorage.setItem(
          "title",
          data.siteDetailsData[0].siteData[0].title
        );
        window.sessionStorage.setItem(
          "fbPixel",
          data.siteDetailsData[0].siteData[0].fbPixel
        );
        window.sessionStorage.setItem(
          "advertisementScript",
          data.siteDetailsData[0].siteData[0].advertisementScript
        );

        window.sessionStorage.setItem(
          "termsAndCondition",
          data.siteDetailsData[0].siteData[0].termsAndCondition
        );
        window.sessionStorage.setItem(
          "privacyPolicy",
          data.siteDetailsData[0].siteData[0].privacyPolicy
        );

        window.sessionStorage.setItem(
          "hasHealthTracker",
          data.siteDetailsData[0].siteData[0].hasHealthTracker
        );
        window.sessionStorage.setItem(
          "siteLogo",
          data.siteDetailsData[0].siteData[0].logo_path
        );
        window.sessionStorage.setItem(
          "groupHospitalAdminId",
          data.siteDetailsData[0].groupHospitalAdminData[0]
            ?.groupHospitalAdminId
        );
        window.sessionStorage.setItem(
          "headerData",
          JSON.stringify(data.siteDetailsData[0].headerData)
        );

        window.sessionStorage.setItem(
          "footerData",
          JSON.stringify(data.siteDetailsData[0].footerData)
        );

        const hosDataVar = JSON.stringify(data.siteDetailsData[0].hospitalData);
        window.sessionStorage.setItem(
          "siteServices",
          JSON.stringify(data.siteDetailsData[0].services)
        );
        if (hosDataVar) {
          window.sessionStorage.setItem(
            "footerhospitalData",
            JSON.stringify(data.siteDetailsData[0].hospitalData)
          );

          window.sessionStorage.setItem(
            "footerHospitalName",
            data.siteDetailsData[0].hospitalData.hospitalName
          );
          window.sessionStorage.setItem(
            "footerHospitalcity",
            data.siteDetailsData[0].hospitalData.city
          );
          window.sessionStorage.setItem(
            "footerHospitaladdress",
            data.siteDetailsData[0].hospitalData.address
          );
          window.sessionStorage.setItem(
            "footerHospitalstateName",
            data.siteDetailsData[0].hospitalData.stateName
          );
          window.sessionStorage.setItem(
            "footerHospitalzipCode",
            data.siteDetailsData[0].hospitalData.zipCode
          );
        }
        if (data.siteDetailsData[0].themeSectionList) {
          for (
            let i = 0;
            i < data.siteDetailsData[0].themeSectionList.length;
            i++
          ) {
            window.sessionStorage.setItem(
              data.siteDetailsData[0].themeSectionList[i].themeSectionName,
              JSON.stringify(
                data.siteDetailsData[0].themeSectionList[i].themeSectionContent
              )
            );
          }
        }
        window.sessionStorage.setItem(
          "doctorDetails",
          JSON.stringify(data.siteDetailsData[0].doctorDetails)
        );

        window.location.href = "/doctor/videoSchedule";

        // this.callLoginFunction();
        // alert("root")
        //window.location.href="/maintenance"
      }
    });
  }

  getLoadHomePage() {
    if (!this.state.siteId) {
      //alert();
      //alert(window.location.host);
      const jsonReq = {
        method: "POST",
        body: JSON.stringify({
          functionName: "getSiteDetails",
          siteId: "",
          siteUrl: window.location.host,
        }),
      };
      // console.log("loading home page");
      // alert(jsonReq.body);
      apiCalling(jsonReq).then((data) => {
        if (data.success === "1") {
          // alert('ok')
          // console.log(data.siteDetailsData[0].siteData[0]);
          var location = data.siteDetailsData[0].siteData[0].siteName;
          let is_theme_based =
            data.siteDetailsData[0].siteData[0].is_theme_based;
          let project_folder = data.siteDetailsData[0].siteData[0]
            ?.project_folder
            ? data.siteDetailsData[0].siteData[0]?.project_folder
            : "DoctorsCabin";

          //alert(project_folder);
          var folder = "DoctorsCabin";
          if (is_theme_based === false && project_folder !== "") {
            folder = project_folder;
          } else {
            folder = data.siteDetailsData[0].siteData[0].themeName;
          }
          if (data.siteDetailsData[0].siteData[0].id) {
          } else {
            folder = "DoctorsCabin";
          }
          //folder = 'SAANVI'
          folderName = folder;
          window.sessionStorage.setItem(
            "groupHospitalAdminId",
            data.siteDetailsData[0].groupHospitalAdminData[0]
              ?.groupHospitalAdminId
          );
          window.sessionStorage.setItem(
            "themeId",
            data.siteDetailsData[0].siteData[0].themeId
          );

          window.sessionStorage.setItem("themeVersion", "0");
          window.sessionStorage.setItem("homeName", folder);
          window.sessionStorage.setItem(
            "dashboard_html",
            data.siteDetailsData[0].siteData[0].dashboard_html
          );
          window.sessionStorage.setItem("reload", "1");
          window.sessionStorage.setItem(
            "theme_custom_color",
            data.siteDetailsData[0].siteData[0].theme_custom_color
          );
          window.sessionStorage.setItem(
            "google_review_link",
            data.siteDetailsData[0].siteData[0].google_review_link
          );
          window.sessionStorage.setItem(
            "contact_emailId",
            data.siteDetailsData[0].siteData[0].emailId
          );
          window.sessionStorage.setItem(
            "contact_no",
            data.siteDetailsData[0].siteData[0].contact_no
          );
          window.sessionStorage.setItem(
            "fav_icon",
            data.siteDetailsData[0].siteData[0].fav_icon
          );
          window.sessionStorage.setItem(
            "seo_description",
            data.siteDetailsData[0].siteData[0].seo_description
          );
          window.sessionStorage.setItem(
            "seo_keywords",
            data.siteDetailsData[0].siteData[0].seo_keywords
          );
          window.sessionStorage.setItem(
            "seo_title",
            data.siteDetailsData[0].siteData[0].seo_title
          );
          window.sessionStorage.setItem(
            "slug",
            data.siteDetailsData[0].siteData[0].slug
          );
          window.sessionStorage.setItem(
            "isIDALogin",
            data.siteDetailsData[0].siteData[0].isIDALogin
          );
          var google_analytics_script =
            data.siteDetailsData[0].siteData[0].google_analytics_script;

          var clarityId = "";
          var googleId = "";
          if (google_analytics_script.length < 30) {
            var Gdata = google_analytics_script.split(",");
            if (Gdata[0]) {
              googleId = Gdata[0];
            }
            if (Gdata[1]) {
              clarityId = Gdata[1];
            }
          }
          window.sessionStorage.setItem(
            "termsAndCondition",
            data.siteDetailsData[0].siteData[0].termsAndCondition
          );
          window.sessionStorage.setItem(
            "privacyPolicy",
            data.siteDetailsData[0].siteData[0].privacyPolicy
          );
          window.sessionStorage.setItem("clarityId", clarityId);
          window.sessionStorage.setItem("googleId", googleId);
          // window.sessionStorage.setItem("googleId",data.siteDetailsData[0].siteData[0].google_analytics_script_id);
          window.sessionStorage.setItem(
            "siteName",
            data.siteDetailsData[0].siteData[0].siteName
          );
          window.sessionStorage.setItem(
            "book_appointment_terms",
            data.siteDetailsData[0].siteData[0].book_appointment_terms
          );
          window.sessionStorage.setItem(
            "video_chat_terms",
            data.siteDetailsData[0].siteData[0].video_chat_terms
          );
          window.sessionStorage.setItem(
            "hospital_working_time",
            data.siteDetailsData[0].siteData[0].hospital_working_time
          );
          window.sessionStorage.setItem(
            "site_url",
            data.siteDetailsData[0].siteData[0].site_url
          );
          window.sessionStorage.setItem(
            "fbPixel",
            data.siteDetailsData[0].siteData[0].fbPixel
          );
          window.sessionStorage.setItem(
            "hasHealthTracker",
            data.siteDetailsData[0].siteData[0].hasHealthTracker
          );
          window.sessionStorage.setItem(
            "footerData",
            JSON.stringify(data.siteDetailsData[0].footerData)
          );

          window.sessionStorage.setItem(
            "siteLogo",
            data.siteDetailsData[0].siteData[0].logo_path
          );
          this.setState({ isLoader: true });
          window.sessionStorage.setItem(
            "siteId",
            data.siteDetailsData[0].siteData[0].id
          );
          const hosDataVar = JSON.stringify(
            data.siteDetailsData[0].hospitalData
          );
          window.sessionStorage.setItem(
            "siteServices",
            JSON.stringify(data.siteDetailsData[0].services)
          );
          window.sessionStorage.setItem(
            "advertisementScript",
            data.siteDetailsData[0].siteData[0].advertisementScript
          );

          if (hosDataVar) {
            window.sessionStorage.setItem(
              "headerData",
              JSON.stringify(data.siteDetailsData[0].headerData)
            );
            window.sessionStorage.setItem(
              "footerhospitalData",
              JSON.stringify(data.siteDetailsData[0].hospitalData)
            );
            window.sessionStorage.setItem(
              "footerHospitalName",
              data.siteDetailsData[0].hospitalData.hospitalName
            );
            window.sessionStorage.setItem(
              "footerHospitalcity",
              data.siteDetailsData[0].hospitalData.city
            );
            window.sessionStorage.setItem(
              "footerHospitaladdress",
              data.siteDetailsData[0].hospitalData.address
            );
            window.sessionStorage.setItem(
              "footerHospitalstateName",
              data.siteDetailsData[0].hospitalData.stateName
            );
            window.sessionStorage.setItem(
              "footerHospitalzipCode",
              data.siteDetailsData[0].hospitalData.zipCode
            );
          }
          //alert(data.siteDetailsData[0].themeSectionList.length);\
          if (typeof data.siteDetailsData[0].themeSectionList !== "undefined") {
            for (
              let i = 0;
              i < data.siteDetailsData[0].themeSectionList.length;
              i++
            ) {
              window.sessionStorage.setItem(
                data.siteDetailsData[0].themeSectionList[i].themeSectionName,
                JSON.stringify(
                  data.siteDetailsData[0].themeSectionList[i]
                    .themeSectionContent
                )
              );
            }
          }
          window.sessionStorage.setItem(
            "doctorDetails",
            JSON.stringify(data.siteDetailsData[0].doctorDetails)
          );
          // alert(window.sessionStorage.getItem("pathname"))
          if (String(window.sessionStorage.getItem("pathname")) != "null") {
            //alert('if')
            window.location.href = window.sessionStorage.getItem("pathname");
            //window.location.href="/maintenance"
          } else {
            // alert('else')
            // window.location.href ="/"
            // window.location.href="/maintenance"
          }
        }
      });
    }
  }

  render() {
    return (
      // <div className="purpleWrap home bg-clr-wthHead">
      <div>
        <Favicon url={window.sessionStorage.getItem("fav_icon")} />
        <Helmet>
          <title></title>
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
        </Helmet>
        {this.state.isLoader === false ? (
          <div
            style={{ padding: "30vh", textAlign: "center", display: "none" }}
          >
            <img src={loaderImg} alt="" />
          </div>
        ) : (
          ""
        )}
        {folderName && (
          <Suspense fallback={<div></div>}>
            <section>
              <Header />
              <Home />
              {/* <ChatBoat/> */}
              <Footer />
            </section>
          </Suspense>
        )}
      </div>
    );
  }
}
